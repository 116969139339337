.container-soon{
    width: 100%;
    margin-top: 100px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: -20px;
    }
    .louise {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      h1 {
        color: #02581B;
        font-size: 50px;
        margin-top: -20px;
        margin-bottom: -10px;
        font-weight: 400;
      }
      h2{
        color: #02581B;
        margin-bottom: -20px;
      }
    }

    .maps-lokasi {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        overflow: hidden;

      }
      .img-lokasi-dekstop {
        width:75%;
        
        border-radius: 10px;
        @media (max-width: 768px) {
          visibility: hidden;
          width: 0%;
          height: 0%;
        }
      }
      .img-lokasi-mobile {
        visibility: hidden;
        width: 0%;
        @media (max-width: 768px) {
          visibility: visible;
          width: 90%;
          border-radius: 20px;
          //height: 75%;
          //margin-top: -20%;
        }
      }
    }
    .container-wa{
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        @media (max-width: 768px) {
          
        }
      .buttonpenawaran {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 30px;
        //margin-left: 15%;
        background-color: #02581B;
        width: 30%;
        
        padding: 12px 30px;
        border: 1px solid white; /* Add this line for black border */
        border-color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: light;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease, color 0.3s ease;
        @media (max-width: 768px) {
            width: 60%;
          justify-content: center;
          align-items: center;
          //margin-left: 15%;
         // margin-top: -30px;
          //margin-bottom: -20px;
          
  
        }
        &:hover {
          background-color: transparent;
          color: #02581B;
          border-color: #02581B;
        }
        
      }
    }

}